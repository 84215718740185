import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenterRounded';
import TerrainIcon from '@mui/icons-material/TerrainRounded';
import SchoolIcon from '@mui/icons-material/SchoolRounded';
import SupportIcon from '@mui/icons-material/SupportRounded';
import HandshakeIcon from '@mui/icons-material/HandshakeRounded';
import StarIcon from '@mui/icons-material/StarRounded';

const items = [
  {
    icon: <FitnessCenterIcon />,
    title: 'Entrenamiento Adaptado a Ti',
    description:
      'Nuestros planes de entrenamiento se ajustan a tus necesidades, ayudándote a mejorar tu rendimiento y alcanzar tus metas personales.',
  },
  {
    icon: <TerrainIcon />,
    title: 'Eventos y Pistas de Calidad',
    description:
      'Organizamos competencias y diseñamos pistas para ofrecer experiencias emocionantes, garantizando un ambiente seguro y profesional.',
  },
  {
    icon: <SchoolIcon />,
    title: 'Escuela de Ciclomontañismo para Todos',
    description:
      'Clases para todas las edades y niveles, donde la técnica y la seguridad se combinan para fomentar la confianza y el dominio sobre la bicicleta.',
  },
  {
    icon: <SupportIcon />,
    title: 'Asesoramiento Experto',
    description:
      'Nuestro equipo cuenta con la experiencia necesaria para ofrecer asesorías que te ayudarán a maximizar tu potencial en el ciclismo.',
  },
  {
    icon: <HandshakeIcon />,
    title: 'Apoyo Constante',
    description:
      'Estamos aquí para ayudarte, desde el primer contacto hasta el logro de tus objetivos, brindando acompañamiento y apoyo continuo.',
  },
  {
    icon: <StarIcon />,
    title: 'Experiencia Personalizada',
    description:
      'Cada uno de nuestros servicios está diseñado con cuidado, asegurándonos de que cada detalle contribuya a una experiencia satisfactoria y única para nuestros ciclistas.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="destacados"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
          Destacados de PachoXC
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Explora por qué nuestros servicios se destacan: enfoque personalizado, mejora continua, experiencias seguras, y un equipo apasionado. Disfruta del apoyo constante y la dedicación en cada detalle.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
