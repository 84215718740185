import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 100, mr: 2 }}>
      <svg version="1.1" viewBox="0 0 2048 400" width="512" height="100" xmlns="http://www.w3.org/2000/svg">
<path transform="translate(1322,68)" d="m0 0h7v69l-17 4-12 5-12 9-7 7-8 13-5 13-1 5v22l4 16 9 16v2l4 2 9 8 13 8 16 4h22l15-4 11-6 10-8 8-9 8-16 3-12 1-16-4-18-8-16-11-12-10-7-10-5-10-3-7-1-1-1v-68h12l21 3 19 5 16 7 12 7 14 11 8 8 10 14 8 16 6 18 3 14 2 20v26l-3 25-6 21-6 14-16 23-3 2-1-5v-5l-14 1-1 6-3 1-6-1v23l-5 4-16 7-3-1-1-45-3-2v-13l-5-1-1 2v6l-1 1h-10v55l-3-3-1-3-9 2-6 5-3-1-1-24-4-4-1-5-5-1v10l-2 3-3-1-1-5-2-1v7l-9 2 4 7 6 7 1 7-4-1-1-4-28-2-1-1v-19l9-5 1-3h-3l-2 4-4 1-1-2-1-17-5-1-1-4 1-6-8-3h-3l-4 7-9 1-2 14h-5v2h5v26l-2 1-8-6v-16h-6l-3-2v-11l-12 2-4 4-7-8-8-13-7-16-5-19-3-23v-27l3-22 5-19 7-17 9-15 11-13 11-10 15-10 17-8 18-5 18-3zm122 61-3 1v2h5l2-3zm-222 6 1 3zm40 19v3l2-1v-2zm-16 2-1 2h3v-2zm179 8m-205 1 1 3h3l-1-3zm10 4 1 2zm216 1-3 2 9 6-1-6zm-45 2-1 4 2 2h6l-1-5-1-1zm-186 1v2h2v-2zm19 4-8 2 2 5-1 7-10 6 2 2 9-3 1-5 9 2 2 2h5l2-2h8l1-5-2-3-9-1-3-1-2-4-3-2zm218 1 1 5 9 1v-2l-8-3zm-235 7v2h3v-2zm189 6-1 3 10 4 3-1-7-6zm19 6-3 4-1 6 2-1 3-6zm16 0v2h2v-2zm15 8-1 3 4-1-1-2zm-24 0-2 2 3 2v-4zm-175 4-5 3 2 1 5-2v-2zm146 3m1 1 2 6 2 1-2-6zm-166 1v3l4 1v-4zm170 6 1 3v-3zm-156 1-3 1-5 1-4 2-7 1 1 3h8l1-1h5l4 3 7 1v-3l-6-8zm160 1-2 1 1 3 4-1-1-3zm-207 2 1 3 3 1v-4zm7 11 2 4 4 2 5-1-5-5zm185 10 1 3 7 6 2-1-6-7zm20 0-1 2h3v-2zm-176 5-1 3 5-1-1-2zm151 3 1 4h3v-4zm-119 6 1 3v-3zm8 3-4 3h4l2-3zm-38 2 1 3h4v-3zm-15 5v2l5 3h2v-2l-5-3zm14 7v3l3 1 2-2zm13 3-1 3 6 1-3-4zm84 0v4h2v-4zm14 9 1 3 1-3zm-15 4v5l2-1v-4zm13 18v2l3-1z" fill="#1E1E1D"/>
<path transform="translate(153,69)" d="m0 0h18l35 1 17 3 11 4 10 5 10 8 8 9 7 12 5 16 2 13v21l-3 17-4 11-7 13-9 10-9 8-16 8-19 5-13 2h-62l-2 34-2 33-1 4-12 1v23h-3l1-46h-25v41l-4-1v-8l-2-2-5 4-5 2-1-7-20-1-1-4 7-2 2-7-9 6v-235h82v112l32-1 12-3 8-4 7-8 2-5 1-10-2-10-5-8-6-5-9-3-5-1-25-1-1-15v-38zm-91 12-1 5 1 1 7-1v-4zm1 23-5 4 1 2 7-3 1 2v-4zm12 8-2 2 1 3h5v-4zm-6 7v3l3 8-7-2h-4l2 4 6 4 5 5-1-9 4-3-2-2-3-8zm133 6v4h3l-1-4zm-120 4-5 1v1l8 1-1-3zm139 3v2l2-1zm-149 15v4l5 1-1-4zm157 4 1 3 5 1-1-3zm-120 1v2h2v-2zm34 39-2 3 2 3h5l-1-5zm-10 2 1 4zm-43 4-3 3 1 2 1-1 7 1 4 1 1-3zm72 0 1 4 4 1-1-5zm-29 1 1 7 2 1-1-7zm-48 6-1 1v7l2 3 14 6 3 6 4-2 4-10v-2l-4 1-4 5-5-2-9-4-2-2-1-7zm61 5 3 5 3 1 2-3-5-1-1-2zm-81 12-1 2 3-1zm5 8 1 8 6-2-2-4zm9 14-2 3h4v-3zm17 1-4 5v2l4-1 3-6zm-26 10-2 2 1 5h5l1-5zm29 7-4 2 7 2 3-1-1-3zm-15 16 1 3 3-1v-2zm-20 16-2 3 3-1zm16 13 1 4 3 2 4-6z" fill="#1E1D1D"/>
<path transform="translate(474,69)" d="m0 0 5 2 6 16 7 19 2 7-11 3-6 1 11-1 7-2 8 24 6 15 17 47 15 41 15 42 6 16v4l-14 1-1-4 1-20-22 1-1 42-4-1v-13l-10 1h-14l-4-4-2-8-5-2-6-18-5-25-3-20 1-4-2-9v-5h-2l-2 12-1 14-5 37-10 5-3 7h-5l-1-1v-36h-20l1 24h-2v-7h-18l1-7 9-30 1-1h40l7-1-9-29-15-49-4-13-3 8-20 66-14 46-13 42-6 21-2 2-1-21h-19v25h-1v-49h-23v43l-4-1v-13l-28 2 1-6 14-37 24-64 17-45 33-88 1-1zm-2 12-5 3 2 4 6-2 2-3zm0 13m1 1v2l5 2-3-4zm5 4m2 1 1 2 2-1zm-79 1 1 3 4-1-1-2zm85 26 1 3 10 3 2 1-2-4-7-3zm-103 5v3h5l-1-3zm65 3 1 10 3-1v-9zm51 15-12 2-2 1v2l10-1 6-3zm-117 3-5 6 1 2 6-6v-2zm-10 11-1 3 4-2zm81 3v3h4v-3zm53 1-1 4h3v-4zm-10 5v4l4-1v-3zm-96 4m111 2-2 3h3zm-54 2-1 3 3-1v-2zm-65 11 2 7h3l-1-5zm116 8-4 6 4-2 3-4zm-5 6m-1 1m-1 2m-1 1-3 5h2l2-5zm-144 1 1 3h3l-1-3zm33 1v3l3-1v-2zm-20 4v4l2-1v-3zm11 1 1 4h2v-4zm0 9-3 3 4 1v-4zm-17 5-2 2 2 2 5-1-1-3zm155 7v3l2 6 3-1-1-6zm-16 7m-19 12 1 3h3l-1-3zm34 1v3l2-2zm-23 6v3l3-1v-2zm13 0v3l3-1-1-2zm-3 10v3l5-2v-1zm-14 3-2 4 2 1 4-4z" fill="#010100"/>
<path transform="translate(1734,71)" d="m0 0h90l-7 11-15 24-15 23-14 22-16 25-11 17-4 6 2 5 6 10v2h-7l-4 1 4 3 3 2 3 6-1 5-1 2h3l3-9-1-4h-2l-1-4 5 2 10 15-6 12 5 4 8 1 5 1 11 17 12 19 7 11 3 8 3 2-1 2-3-2h-10l-2 10-5 1-4-4-4 2-5 4v12h-2l-1-10 2-5 7-5v-8l-4-12-4-1h-8l-1-12h-15v52h-2l-1-11v-20l-18-1 1 14 1 6-3-1-1-2-1-12v-12h-2l-1 8-7-1-6-9-2-7-2-31-1 1-1 21-2 2-7-10-8-13-1-3h-3l-2 5-10 17-13 20-7 11-2 3-4-2-4-5-7 1-7 3-1 24-2 2-5-1-3-8-5-5v-2l-7 1-2 2-7 3v13l-1 1-17 1-1-1-1-21-3-9-7 5-3 1-1 22h-8l-10 2 1-4 13-18 21-28 14-19 12-16 14-19 11-15 12-17 12-16 28-40 12-17 14-20 16-23 4-7 2 2 1 3 9 1-5-4-3-5zm24 34-1 4h3l1-4zm-4 12-1 4 3-1v-3zm-20 2m-2 1v3l2-3zm-21 23 2 12h3l3-9-3-3zm-67 64 1 3 2-1-1-2zm86 5v3l7 1-4-4zm5 10-2 1-4 10 5 5 5 1v-6h6l-2-8-5-1-1-2zm9 3m-28 7v3l3-3zm-5 13-1 2 4-1zm-98 6m-1 1-4 6 3 2 4-1-2-7zm40 7-1 3 5 1 1-4zm87 2-1 2 4-1zm-139 5-4 4-3 5v5l9 3 2-3 1-11-2-3zm56 3-1 4 4-1v-3z" fill="#DD0D1B"/>
<path transform="translate(1919,65)" d="m0 0h31l24 3 17 4 2 1 1 3v88l-4-2-7-8-10-8-12-6-14-4h-24l-14 4-11 6-10 8-7 8-8 14-4 12-1 7v13l3 16 7 14 11 13 12 9 14 6 9 2h23l12-3 14-7 9-7 10-11h1v46l-1 39h-4l-3-9-3-2-2-9-5 2-5-1v-13l-4-19-5 1-6 4-3 3-8 2 3 4 1 6-4 2-1 4 3 1 1-2 1 9v7h5l1 4h-16l-3 1-2 4h-2l1-9 1-3h-3l-1 7-7 1 1 4 2 4v5l-9-5-12 6h-2l1-3 10-5 2-7-2-10-3-5-2-7-17-1v35h-1l-1-16-1-36-4-1-2-1-1 2-12 4-1 1-1 9-5-5-3-6-3-4-3-15-2 3h-4l3 3v-2l2 1-1 3 1 2v8l-6-1-10-7-5-2 3 3 11 8 6 2v6l-3 3-2 9-2 7-4-2-11-11-12-17-9-19-5-17-3-16-1-16v-19l1-16 4-20 5-15 8-17 9-13 10-12 11-9 10-7 19-9 16-5 17-3zm-66 28-1 2h4v-2zm75 0v2l4-1zm-103 44-1 2h3v-2zm40 20v2l3-1zm-15 1-1 3 3-1v-2zm-27 9 2 4h2l-1-4zm10 5m-16 3m1 1v2l2-1zm2 2m18 1-1 3h-8l3 5-1 5-4 3v2l-3 1 2 1 6-2 3-4 8 3 4 3 4-4h8l1-5-3-3-10-2-4-3-1-3zm-17 9 1 3 2-3zm10 10 1 3zm-9 1-2 2h3l1-2zm9 3 1 2 1-2zm-1 2m32 8-7 1v2l8-1 1-2zm-23 5v2l7 2-1-4zm14 7-6 2-3 2-7 2v3l5-1 6-2 4-1 2 5h10l-6-8zm-44 3v3h4l-1-3zm7 11 2 4 3 2h5l-2-4-4-2zm57 4v2h2v-2zm9 7 1 4 1-4zm-38 4v3h4l-1-3zm33 9m1 1m1 1m1 1m3 0v3l4-1v-2zm-17 8 1 2h2v-2zm43 0v2h3v-2zm-70 8-5 3v7l2 2 9-1-1-8z" fill="#DD0D1B"/>
<path transform="translate(732,65)" d="m0 0h31l24 3 16 4 3 2 1 91-14-14-14-9-14-5-13-2-16 1-16 5-9 5-10 8-8 9-6 10-5 14-1 7v16l3 14 8 16 13 14 13 8 14 5 5 1h23l12-3 14-7 9-7 8-8 2-3h2l-1 59-1 26h-4l-3-9-4-4-1-7-5 2h-4l-2-19-3-15-9 4-5 5-8 2 3 4v7l-4 2 1 3 2 1 1-4 1 17 7 3v1l-21 1-1 5-2-3-1-4 3-3v-2l-3-1-1 7-6 2h-2l5 12-1 2-7-5h-5l-6 4-6 1v-2l10-5h2l1-5v-11l-4-6-1-8h-18l-1 34h-1l-1-16-1-36-6-1-6 3-9 3v8l-4-1-3-8-3-4-3-17-2 4-3 2 3 3 1 9-3 1-8-4-4-4-5-1 5 4 7 6 6 2 1 4-6 16-1 5-4-2-13-13-10-15-8-16-6-19-3-16-1-10v-32l2-18 5-20 7-17 9-16 12-14 12-11 11-7 16-8 15-5 14-3zm-66 28-1 2h3v-2zm75 0v2h3v-2zm-104 44v2h3v-2zm41 20v2l3-1zm-16 1-1 2 4-1zm-25 9-1 3h4v-2zm-7 8 1 3 2-1zm21 3-2 3-7 1 3 6-1 4-6 5v1l6 1 2-5h6l6 4 4-1 1-2h9l1-5-5-3-7-1-4-4h-2l-2-4zm-17 10-1 2 3-1zm2 10m-2 1-1 2 3-1zm10 3-1 2h2zm-2 2m32 8-6 2 4 1 4-1v-2zm-20 4-2 1v2l4 2h3v-4zm14 8-8 1v3l-9 1-2 1v3l8-1 5-3 3 1 2 4 8 1v-4l-6-7zm-47 3 2 4 3-1v-3zm7 10 2 4 6 4 3-1-1-4-6-3zm58 5m8 7 1 4 2-1-1-3zm-37 4-1 2 5 1-1-3zm33 10m2 1m3 1 1 3 4-1v-2zm-14 8v2h2v-2zm41 0v2l3-1zm-73 8-2 2v8l3 2 7-1 1-4-2-5-2-2z" fill="#1E1D1D"/>
<path transform="translate(1102,69)" d="m0 0h28l6 1v256h-3l-4-3h-25v-2h24v-45h-5l-1-4v-8l-12-3 2 6-1 1h-6l-6-3 1 8 2 1-2 5-1 40h-9l-1-6v-12l1-6-8-1v-13l-13 2-2 7h-8v43h-6v-107h-78l-1-5v-14l1-9 1-1-2-6v-29l78-1 1-91zm2 8 2 2v-2zm3 12-2 1v4l9 4 6 2-3-3v-8zm-29 12v2h3v-2zm-87 71v3l2 1 2-1-1-3zm37 1v4l3 2h3l-1-4-2-2zm100 6-1 3 1 4h3v-6zm-88 2 1 6 5 4 6-1v-4l-8-5zm-63 2v4l4 1 2-1-1-4zm39 2v3h3l-1-3zm-25 3-2 3h3zm42 1-1 5 2 2 1-6zm48 2v4h2l1-3zm26 5-1 3 5 5 2 1-1-7-2-2zm-58 1-1 5h3l-1-5zm43 1 1 2 2-1zm-71 2-1 5 3 1 1-5zm-35 1-1 5 2 1 3-2-1-4zm145 2-2 3h3l1-3zm-100 1-1 2v10l-1 2 4-1v-10zm50 0 1 3h3v-3zm17 1 1 3h2v-3zm17 5-3 4 5-1 2-1v-2zm-106 3-1 3 3 1v-4zm73 4-2 1 1 5 4 1 2-1-1-5zm15 11-1 2 3 7-8 2-2 4 1 3 10 3 1-8 6-1-1-3-5-2v-6zm33 6-2 4 4-1-1-3zm-8 10-8 5 2 1 7-4zm-1 9 1 3zm-21 3v3l4-1zm-18 14-4 1 1 4 5-1 2-4z" fill="#1E1E1D"/>
<path transform="translate(885,69)" d="m0 0h9l7 2 1 2h2l3-4 11 1v8l-1 5h4l1-12 1-1h7l3 3 1 6h2l-1-9 28-1 2 3v150l-1 69-9 3-5 5-5 2v29l-5 1-1-2v-20h-5v20l-1 2h-7l-1-1-1-42-2-3v-11l-4-1-1 7-1 1h-8l1 50-2-1v-5l-7 1-8 5-2-1v-19l-4-5-3-7 1-48 1-16 8-2v-2l-9 2-2-4 1-7v-29l-1-114 10 5-6-8zm72 5v9h3l-1-9zm-60 11v1l14 1 1-2zm2 11-2 5 1 2h3l1-7zm-6 67-6 9 1 10-3 5 5 1 8-12h5l3 4 5 3 2 9 3-1v-7l-4-5 2-10-6 2v-6l-3 1-2 4-10 5 1-5 3-4-1-3zm8 21-5 4-3 1-1 3 1 2 4 1 6-9v-2zm27 1 1 3 2-1v-2zm-10 15-2 2 1 3 2-2zm10 1-2 2v8l6-3 1-5-2-2zm-13 4-6 4 1 2 5-1 1-5zm-29 2-2 3 7 1v-4zm29 14 1 2 4 1-1 5-2 4 4-1 4-4h3l-1-5-6-2zm-19 4v2l3-1zm36 6v4h3l-2-4z" fill="#1E1E1D"/>
<path transform="translate(1551,71)" d="m0 0h90l6 9 15 27 8 15 6 9 3 5-1 4-11 16-12 17-14 20-2 3-4-2-10-15-11-16-14-20-44-64-5-7zm69 51-1 4 4 2 1-4z" fill="#DD0D1B"/>
<path transform="translate(571,175)" d="m0 0h10l6 5 2 9v26l-2 7-5 4-2 1h-10l-5-4-2-4-1-6v-21l1-10 4-5zm1 8-2 3v30l1 3 5 1 4-2 1-2v-31l-2-2z" fill="#DD0D1B"/>
<path transform="translate(1165,174)" d="m0 0 9 2 5 6 1 3v32l-3 6-6 4h-10l-5-4-2-5-1-10v-11l1-14 4-6zm1 8-4 2-1 3v28l2 5 7-1 2-3v-31l-4-3z" fill="#DD0D1B"/>
<path transform="translate(844,174)" d="m0 0 8 1 6 5 2 6v6l-2 2h-6l-3-10-5-1-2 1-1 8v20l1 7 7-1 2-9 1-1h6l2 3-1 8-6 7-2 1h-10l-5-3-3-6v-34l3-6z" fill="#DD0D1B"/>
<path transform="translate(289,175)" d="m0 0h6l1 42 10 1 2 5-2 2h-18v-49z" fill="#DD0D1B"/>
<path transform="translate(1859,303)" d="m0 0 6 1 1 1 1 17-4 1-3-3-1-8z" fill="#DD0D1B"/>
<path transform="translate(672,303)" d="m0 0 6 1 1 1 1 18-5-1-2-3-1-7z" fill="#211D1C"/>
<path transform="translate(1768,297)" d="m0 0h1v38h-2v-33z" fill="#DD0D1B"/>
<path transform="translate(671,294)" d="m0 0 8 1 1 7-9-3-1-4z" fill="#1E1E1D"/>
<path transform="translate(1957,329)" d="m0 0 4 4h13v2l-8 1h-10z" fill="#DD0D1B"/>
<path transform="translate(769,328)" d="m0 0 2 3 1 2h15v2l-8 1h-10l-1-3z" fill="#221D1C"/>
<path transform="translate(1858,292)" d="m0 0 7 5 2 5-9-3-1-6z" fill="#DD0D1B"/>
<path transform="translate(1813,320)" d="m0 0h6l3 3-7 1-1 11h-2v-8z" fill="#DD0D1B"/>
<path transform="translate(115,307)" d="m0 0h1l1 23h-3z" fill="#433C3B"/>
<path transform="translate(1103,323)" d="m0 0 2 4-2 4h-3l1-6z" fill="#2C1C1C"/>
<path transform="translate(547,304)" d="m0 0h1v24h-1z" fill="#1E1E1D"/>
<path transform="translate(1790,326)" d="m0 0 3 3v3l6 2-4 1-4-3z" fill="#DD0D1B"/>
<path transform="translate(315,311)" d="m0 0h1l1 16h-2z" fill="#171716"/>
<path transform="translate(1767,241)" d="m0 0 5 5-1 2-5-2z" fill="#DD0D1B"/>
<path transform="translate(1736,323)" d="m0 0 5 2 1 4-2-1v-2l-4-2z" fill="#DD0D1B"/>
<path transform="translate(1735,230)" d="m0 0 2 1-1 4h-2z" fill="#DD0D1B"/>
<path transform="translate(1826,330)" d="m0 0 3 3-2 2z" fill="#DD0D1B"/>
<path transform="translate(510,313)" d="m0 0h1v7h-1z" fill="#1E1E1D"/>
<path transform="translate(1742,330)" d="m0 0 2 4h-2z" fill="#DD0D1B"/>
<path transform="translate(1744,217)" d="m0 0 3 1z" fill="#DD0D1B"/>
<path transform="translate(74,326)" d="m0 0" fill="#1E1E1D"/>
<path transform="translate(510,325)" d="m0 0 2 1z" fill="#1E1E1D"/>
<path transform="translate(1925,333)" d="m0 0" fill="#DD0D1B"/>
<path transform="translate(1735,322)" d="m0 0" fill="#DD0D1B"/>
</svg>
    </SvgIcon>
  );
}
