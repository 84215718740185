import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/system';
import { Link } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const userTestimonials = [
  {    
    name: 'Liliana Beltran',
    occupation: 'Ciclista',
    testimonial:
      "Excelente evento, muy buena la organización, una pista súper bien trazada, y sobre todo gente muy amable y querida, definitivamente la mejor pista … la disfrutamos… 😀Gracias !!!",
    instagramLink: "https://www.instagram.com/p/C8-i59QPwqx/?img_index=1"
  },
  {
    name: 'Ana Roa',
    occupation: 'Ciclista Elite',
    testimonial:
      "Tremendo evento, tremendo circuito",
    instagramLink: "https://www.instagram.com/p/C8-i59QPwqx/?img_index=1"
  },
  {
    name: 'Jhonatan Botero Villegas',
    occupation: 'Ciclista Elite',
    testimonial:
      'Esta pista de Manizales siempre será una locura y el nivel de los corredores le pusieron mucho más pícante 🤪🔥, de verdad muy contento con las sensaciones y los resultados este fin de semana...',
    instagramLink: "https://www.instagram.com/p/C88NO0-PfH7/"
  },
  {
    name: 'Marian Buitrago Martinez',
    occupation: 'Ciclista',
    testimonial:
      "Que clase tan espectacular 🔥🔥🔥 gracias gracias 🙏",
    instagramLink: "https://www.instagram.com/p/C7hcgWfvgY2/"
  },
  {
    name: 'Gary Mauricio',
    occupation: 'Ciclista',
    testimonial:
      "Para mí la mejor pista que e conocido en colombia . Felicitaciones a Pacho y a sus colaboradores por tener un excelente escenario.",
    instagramLink: "https://www.instagram.com/p/C8uSn7HsYLZ/?hl=es-la"
  },
  {
    name: 'Paulo Andres Garcia M',
    occupation: 'Ciclista',
    testimonial:
      "Admiración total. Su dedicación y esfuerzo son verdaderamente inspiradores",
    instagramLink: "https://www.instagram.com/p/C97XFpFxzsM/?img_index=1"
  },
];

export default function Testimonials() {
  const theme = useTheme();

  return (
    <Container
      id="testimonios"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Testimonios
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Lo que dicen de nosotros...
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={null}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <Link
                  href={testimonial.instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ ml: 2 }}
                >
                  <ChatBubbleOutlineIcon sx={{ color: 'text.secondary' }} />
                </Link>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
