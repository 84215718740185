import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import YoutubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import MailCenter from '@mui/icons-material/Mail';
import SitemarkIcon from './SitemarkIcon';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="https://pachoxc.com/">
        PachoXC.com
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <SitemarkIcon />
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
              ¡Contáctanos!
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Estamos aquí para ayudarte a alcanzar tus metas sobre la bicicleta.
              No dudes en contactarnos para cualquier pregunta o para inscribirte en nuestros programas.
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              Teléfonos: <Link href="tel:+573008714091">+57 300 8714091</Link> <Link href="tel:+573017713517">+57 301 7713517</Link> 
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              Correo Electrónico: <Link href="mailto:pacho@pachoxc.com">pacho@pachoxc.com</Link>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              TrainingPeaks: <Link href="https://www.trainingpeaks.com/coach/httpshometrainingpeakscomattachtocoachsharedkeykg56c6cl6#about" target="_blank">Perfil de TrainingPeaks</Link>
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              Canal Youtube: <Link href="https://www.youtube.com/@PachoXC" target="_blank">Canal PachoXC</Link>
            </Typography>         
          </Box>
        </Box>
      </Box>        
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          {/* <Link color="text.secondary" variant="body2" href="#">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms of Service
          </Link> */}
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://instagram.com/pachoxc"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.youtube.com/@PachoXC"
            aria-label="Youtube"
            sx={{ alignSelf: 'center' }}            
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://api.whatsapp.com/send/?phone=573017713517&text=Hola%20Pacho!%20Vengo%20desde%20la%20pagina%20y%20estoy%20interesado%20en..."
            aria-label="WhatsApp"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="mailto:pacho@pachoxc.com"
            aria-label="Correo Electronico"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailCenter />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.trainingpeaks.com/coach/httpshometrainingpeakscomattachtocoachsharedkeykg56c6cl6#about"
            aria-label="Training Peaks"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FitnessCenter />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
